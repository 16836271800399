
import Vue from 'vue';
import SearchInput from '@/components/SearchInput.vue';
import { SimulationResourceCategory, SimulationResourceCategoryText } from '@/types/simulation';
import CreateResourceModal from '../create-resource-modal/index.vue';

export default Vue.extend({
  components: { SearchInput, CreateResourceModal },
  data() {
    return {
      category: '全部',
      categoryOptions: [{ value: 'all', label: '全部' }, ...Object.keys(SimulationResourceCategoryText).filter((v) => [SimulationResourceCategory.homePage, SimulationResourceCategory.loadingPage].indexOf(Number(v)) === -1).map((v) => ({ value: SimulationResourceCategory[v], label: SimulationResourceCategoryText[v] }))],
      createResourceModalVisible: false,
    };
  },
  computed: {
    hasSelectedCatalogue(): boolean {
      return (
        this.$store.getters['customizedSimulationResourcesModule/selectedCatalogueId'] !== undefined
      );
    },
    selectCategory(): string {
      return this.$store.state.customizedSimulationResourcesModule.selectCategory;
    },
  },
  methods: {
    searchInputChange(value) {
      this.$store.commit('customizedSimulationResourcesModule/setSearchText', value);
    },
    createResourceClick() {
      this.$store.commit('customizedSimulationResourcesModule/resetEditSimulationResource');
      this.createResourceModalVisible = true;
    },
    selectCategoryChange(value) {
      this.$store.commit('customizedSimulationResourcesModule/setSelectCategory', value);
    },
  },
});
