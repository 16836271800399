
import { SimulationResource } from "@/api/customized-simulation-resources";
import { SimulationResourceCategory, SimulationResourceCategoryText } from "@/types/simulation";
import { startConvertModel } from "@/utils/convert-model";
import { uploadFile } from "@/utils/upload-file";
import { Message } from "element-ui";
import { ElForm } from "element-ui/types/form";
import Vue from "vue";

export default Vue.extend({
  name: "CreateResourceModal",
  props: {
    visible: { type: Boolean }
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "",
        category: undefined as number | undefined
      },
      categoryOptions: [
        {
          value: SimulationResourceCategory.modelBrowsing,
          label: SimulationResourceCategoryText[SimulationResourceCategory.modelBrowsing]
        },
        {
          value: SimulationResourceCategory.componentIntroduction,
          label: SimulationResourceCategoryText[SimulationResourceCategory.componentIntroduction]
        },
        {
          value: SimulationResourceCategory.auxiliaryPositioning,
          label: SimulationResourceCategoryText[SimulationResourceCategory.auxiliaryPositioning]
        },
        {
          value: SimulationResourceCategory.twoDimensionalAndThreeDimensionalComparison,
          label:
            SimulationResourceCategoryText[
              SimulationResourceCategory.twoDimensionalAndThreeDimensionalComparison
            ]
        }
      ],
      rules: {
        name: [{ required: true, message: "请输入资源名称", trigger: "blur" }],
        category: [{ required: true, message: "请选择资源类别", trigger: "change" }]
      },
      modelName: "",
      modelId: "",
      convertState: 0,
      fileName: "",
      fileSize: 0,
      fileUrl: ""
    };
  },
  computed: {
    hasEditItem(): boolean {
      return this.$store.getters["customizedSimulationResourcesModule/hasEditItem"];
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:visible", false);
    },
    uploadModel(_file) {
      this.loading = true;
      const file = _file.raw;
      const index = file.name.lastIndexOf(".");
      const type: string = file.name.slice(index);
      const uploadableExtensions = [".rvt"];
      if (!uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${uploadableExtensions.join(" ")}文件`);
        return;
      }
      this.fileName = file.name;
      this.fileSize = file.size;
      this.modelName = file.name;
      const setFile = async data => {
        if (data.fileId) {
          this.modelId = data.fileId;
        }
        if (data.percentage === "100") {
          this.fileUrl = data.filePath;
          this.loading = false;
          startConvertModel({
            modelId: this.modelId,
            resolve: () => {
              this.convertState = 2;
            },
            reject: (errorMessage: string) => {
              Message.error(errorMessage);
            }
          });
        }
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
    validate() {
      let res = false;
      (this.$refs["createResourceModalForm"] as ElForm).validate(valid => {
        if (valid) {
          res = true;
        } else {
          res = false;
        }
      });
      return res;
    },
    async confirmCreateOrEditSimulationResource() {
      if (this.validate() && this.modelId) {
        await this.$store.dispatch(
          "customizedSimulationResourcesModule/createOrEditSimulationResource",
          {
            category: this.ruleForm.category,
            name: this.ruleForm.name,
            modelId: this.modelId,
            convertState: this.convertState,
            fileName: this.fileName,
            fileSize: this.fileSize,
            fileUrl: this.fileUrl
          }
        );
        this.closeModal();
        return;
      }
      if (!this.modelId) {
        Message.warning("请上传模型文件");
      }
    },
    async editDetail() {
      if (this.convertState !== 2) {
        Message.warning("模型正在转换中，请稍等");
        return;
      }
      await this.confirmCreateOrEditSimulationResource();
      if (this.validate()) {
        if (!this.hasEditItem) {
          this.$store.commit(
            "customizedSimulationResourcesModule/setEditSimulationResource",
            this.$store.state.customizedSimulationResourcesModule.simulationResourceList[0]
          );
        }
        this.$store.commit("customizedSimulationResourcesModule/resetDetail");
        switch (this.ruleForm.category) {
          case SimulationResourceCategory.modelBrowsing:
            this.$router.push({ path: "/modelBrowsing" });
            break;
          case SimulationResourceCategory.componentIntroduction:
            this.$router.push({ path: "/componentIntroduction" });
            break;
          case SimulationResourceCategory.auxiliaryPositioning:
            this.$router.push({ path: "/auxiliaryPositioning" });
            break;
          case SimulationResourceCategory.twoDimensionalAndThreeDimensionalComparison:
            this.$router.push({ path: "/twoThreeDimensionalComparison" });
            break;
          default:
            break;
        }
      }
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          (this.$refs["createResourceModalForm"] as ElForm)?.resetFields();
          this.modelId = "";
          this.convertState = 0;
          this.fileName = "";
          this.fileSize = 0;
          this.fileUrl = "";
          this.modelName = "";
          if (this.hasEditItem) {
            const editItem: SimulationResource = this.$store.state
              .customizedSimulationResourcesModule.editSimulationResource;
            this.modelId = editItem.uploadFile.fileId;
            this.convertState = editItem.uploadFile.fileConvertState;
            this.fileName = editItem.uploadFile.fileName;
            this.fileSize = editItem.uploadFile.fileSize;
            this.fileUrl = editItem.uploadFile.fileUrl;
            this.modelName = editItem.uploadFile.fileName;
            this.ruleForm.name = editItem.designFile.name;
            this.ruleForm.category = editItem.designFile.classType;
            if (this.convertState !== 2) {
              startConvertModel({
                modelId: this.modelId,
                resolve: () => {
                  this.convertState = 2;
                },
                reject: (errorMessage: string) => {
                  Message.error(errorMessage);
                }
              });
            }
          }
        }
      }
    }
  }
});
