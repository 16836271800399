
import {
  GetSimulationResourcesResponse,
  SimulationResource,
} from '@/api/customized-simulation-resources';
import { myConfirm } from '@/utils/confirm';
import Vue from 'vue';
import Ellipsis from '@/components/Ellipsis.vue';
import { SimulationResourceCategory, SimulationResourceCategoryText } from '@/types/simulation';
import { checkModelConvertStatus, ConvertStatus } from '@/utils/check-model-convert';
import { Message } from 'element-ui';
import CreateResourceModal from '../create-resource-modal/index.vue';

export default Vue.extend({
  data() {
    return {
      editResourceModalVisible: false,
    };
  },
  computed: {
    searchText() {
      return this.$store.state.customizedSimulationResourcesModule.searchText;
    },
    selectCategory() {
      return this.$store.state.customizedSimulationResourcesModule.selectCategory;
    },
    simulationResourceList(): GetSimulationResourcesResponse['items'] {
      return this.$store.state.customizedSimulationResourcesModule.simulationResourceList.filter((v) => {
        if (this.selectCategory === 'all') {
          return v.designFile.name.includes(this.searchText);
        }
        return v.designFile.name.includes(this.searchText) && v.designFile.classType === SimulationResourceCategory[this.selectCategory];
      });
    },
  },
  methods: {
    handleDelete(id) {
      myConfirm(this, {
        text: '确认要删除此资源？',
        resolve: () => {
          this.$store.dispatch('customizedSimulationResourcesModule/deleteSimulationResources', {
            id,
          });
        },
      });
    },
    getCategoryName(type) {
      return SimulationResourceCategoryText[type];
    },
    handleEdit(item) {
      this.$store.commit('customizedSimulationResourcesModule/setEditSimulationResource', item);
      this.$store.commit('customizedSimulationResourcesModule/setEditSimulationResourceId', item.designFile.id);
      this.editResourceModalVisible = true;
    },
    async viewModel(item: SimulationResource) {
      const convertRes = await checkModelConvertStatus(item.uploadFile.fileId);
      if (convertRes) {
        const { href } = this.$router.resolve({
          path: `/model-view?id=${item.uploadFile.fileId}`,
        });
        window.open(href, '_blank');
      }
    },
  },
  components: { Ellipsis, CreateResourceModal },
});
