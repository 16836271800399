
import Vue from 'vue';
import LeftRightLayout from '@/layout/left-right-layout/left-right-layout.vue';
import TopBotLayout from '@/layout/top-bot-layout/top-bot-layout.vue';
import CatalogueHeader from '@/components/catalogue/catalogue-header.vue';
import CatalogueItem from '@/components/catalogue/catalogue-item.vue';
import CreateCatalogueModal from '@/components/catalogue/create-catalogue-modal.vue';
import ToolHeader from '@/features/customized-simulation-resources/tool-header/index.vue';
import { CustomizedSimulationResourcesState } from '@/store/modules/customized-simulation-resources';
import CustomizedSimulationResourcesTable from '@/features/customized-simulation-resources/resources-table/index.vue';

export default Vue.extend({
  components: {
    LeftRightLayout,
    CatalogueHeader,
    CatalogueItem,
    CreateCatalogueModal,
    TopBotLayout,
    ToolHeader,
    CustomizedSimulationResourcesTable,
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '定制仿真资源',
        path: 'customizedSimulationResources',
      },
    ]);
    this.$store.dispatch('customizedSimulationResourcesModule/init');
  },
  computed: {
    editCatalogueName() {
      return this.$store.getters['customizedSimulationResourcesModule/editCatalogueName'];
    },
    catalogueList(): CustomizedSimulationResourcesState['catalogueList'] {
      return this.sortList(this.$store.state.customizedSimulationResourcesModule.catalogueList);
    },
  },
  data() {
    return {
      createCatalogueModalVisible: false,
    };
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
    createCatalogue() {
      this.$store.commit('customizedSimulationResourcesModule/setEditCatalogue', {});
      this.createCatalogueModalVisible = true;
    },
    createOrEditCatalogueConfirm(inputValue) {
      this.$store.dispatch(
        'customizedSimulationResourcesModule/createEditSimulationResourceDirectory',
        {
          name: inputValue,
          id: this.$store.state.customizedSimulationResourcesModule.editCatalogue?.id,
        },
      );
    },
    isSelected(id) {
      return id === this.$store.getters['customizedSimulationResourcesModule/selectedCatalogueId'];
    },
    isCreateBySelfOrAdmin(createUserId) {
      return createUserId === this.$store.state.userInfo.userId || this.$store.getters.isAdmin;
    },
    selectCatalogue(item) {
      this.$store.commit('customizedSimulationResourcesModule/setSelectedCatalogue', item);
      this.$store.dispatch('customizedSimulationResourcesModule/getSimulationResources', {
        catalogeId: item.id,
      });
    },
    editCatalogue(item) {
      this.$store.commit('customizedSimulationResourcesModule/setEditCatalogue', item);
      this.createCatalogueModalVisible = true;
    },
    deleteCatalogue(item) {
      this.$store.dispatch(
        'customizedSimulationResourcesModule/deleteSimulationResourceDirectory',
        { id: item.id },
      );
    },
  },
});
