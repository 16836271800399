import { convertModel, getConvertProgress } from "@/api/model-view";
import { Message } from "element-ui";
export enum ConvertStatus {
  notStart,
  success,
  inProgress,
  fail
}
export const checkModelConvertStatus = async (modelId: string) => {
  const getConvertProgressRes = await getConvertProgress(modelId);
  if (getConvertProgressRes.status.error === 20012) {
    await convertModel(modelId);
    Message.warning(`模型正在转换中。`);
    return false;
  }
  if (getConvertProgressRes.status.error === 0 && getConvertProgressRes.convertStatus === 0) {
    return true;
  }
  if (getConvertProgressRes.convertStatus === 1) {
    Message.warning(
      `模型正在转换中。${
        getConvertProgressRes.progressDesc ? `进度：${getConvertProgressRes.progressDesc}` : ""
      }`
    );
    return false;
  }
  if (getConvertProgressRes.status.error !== 0) {
    Message.warning(`模型转换失败，请联系管理员或更换模型。`);
    return false;
  }
};
